import PageContainer from "../components/PageContainer";
import RegistrationFieldsSelector from "../components/setupEventComponents/RegistrationFieldsSelector";

function RegistrationFieldsPage () {
    return <PageContainer>
        <RegistrationFieldsSelector/>
    </PageContainer>;
}


export default RegistrationFieldsPage;