import { Box, Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import InnerBox from "../components/InnerBox";
import apiClient from "../services/apiclient";

function EventDashboard() {
  const { eventURN } = useParams();

  const [event, setEvent] = useState(null); // Initial state set to null
  const [visibilityIndex, setVisibilityIndex] = useState(null);
  const [visibility, setVisibility] = useState(null);
  const eventName = eventURN.toUpperCase().replace(/-/g, " ");

  useEffect(() => {
    window.scrollTo(0, 0);
    apiClient
      .get(`/manage/${eventURN}/details`)
      .then((response) => {
        setEvent(response.data);
        setVisibility(response.data.visibility_type);
      })
      .catch((error) => {
        console.error("Failed to fetch event data:", error);
      });
  }, [eventURN]); // Dependency array includes eventURN

  const handleVisibilityToggle = () => {
    const visibilityList = ["PUBLIC", "PRIVATE", "UNLISTED"];
    const nextIndex = (visibilityList.indexOf(visibility) + 1) % visibilityList.length;
    const nextVisibility = visibilityList[nextIndex];
    setVisibility(nextVisibility)
    apiClient
      .post(`/manage/${eventURN}/visibility/toggle`, {
        visibility: nextVisibility,
      })
      .then()
      .catch();
  };
  return (
    <PageContainer>
      <h2>{eventName}</h2>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "auto",
            md: "1fr 1fr 1fr",
          },
          width: "80%",
        }}
      >
        <InnerBox>
          <p>hello world</p>
          {/* Example field */}
        </InnerBox>
        <InnerBox>
          <p>hello world</p>
        </InnerBox>
        <InnerBox>
          <h3>zichtbaarheid</h3>
          {event && (
            <Button
              variant="contained"
              sx={{
                borderRadius: "2rem",
                boxShadow: "none",
                textTransform: "lowercase",
                width: "7rem",
                // margin: "1rem 0",
                backgroundColor: `var(--${visibility})`,
                "&:hover": {
                  backgroundColor: `var(--${visibility})`,
                },
              }}
              onClick={handleVisibilityToggle}
            >
              {(() => {
                switch (visibility) {
                  case "PUBLIC":
                    return "openbaar";
                  case "UNLISTED":
                    return "verborgen";
                  case "PRIVATE":
                    return "privé";
                  default:
                    return "unknown";
                }
              })()}
            </Button>
            )}
            <Typography variant="p" sx={{
              textAlign: "center",
              margin: "1rem 0",
            }}>
              {(() => {
                switch (visibility) {
                  case "PUBLIC":
                    return "Het evenement staat openbaar zichtbaar op de events-pagina. Iedereen kan het evenement zien.";
                  case "UNLISTED":
                    return "Het evenement is publiek bereikbaar, maar wordt niet uitgelicht op de events-pagina. Mensen met de link naar het evenement, kunnen het evenement zien.";
                  case "PRIVATE":
                    return "Het evenement is verborgen. Enkel de organisator en managers kunnen het evenement zien.";
                  default:
                    return "unknown";
                }
              })()}
            </Typography>
          
        </InnerBox>
      </Box>
    </PageContainer>
  );
}

export default EventDashboard;
