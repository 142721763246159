import { TextField, Box } from "@mui/material";
import { useState } from "react";

function AddressForm({ defaultAddress, onChange, addressError ,  numberError, postalCodeError, cityError }) {
  const [address, setAddress] = useState({
    street: defaultAddress ? defaultAddress.street : "",
    number: defaultAddress ? defaultAddress.number : "",
    postalCode: defaultAddress ? defaultAddress.postalCode : "",
    city: defaultAddress ? defaultAddress.city : "",
  });
  
  // Handle changes only on blur (when user finishes editing)
  const handleBlur = (field, value) => {
    onChange({ ...address, [field]: value });
  };

  const handleInputChange = (field, e) => {
    setAddress((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr 1fr 1fr",
          md: "1fr 1fr 1fr 1fr",
        },
        gap: "1rem",
        width: "100%",
      }}
    >
      <TextField
        label="Straat"
        variant="outlined"
        value={address.street}
        onChange={(e) => handleInputChange("street", e)}
        onBlur={() => handleBlur("street", address.street)}
        error={!!addressError.street}
        helperText={addressError.street}
        sx={{
          gridColumn: { xs: "1/3", md: "1/4" },
        }}
      />
      <TextField
        label="Nummer"
        variant="outlined"
        value={address.number}
        onChange={(e) => handleInputChange("number", e)}
        onBlur={() => handleBlur("number", address.number)}
        error={!!addressError.number}
        helperText={addressError.number}
        sx={{
          gridColumn: { xs: "3/4", md: "4/5" },
        }}
      />
      <TextField
        label="Postcode"
        variant="outlined"
        value={address.postalCode}
        onChange={(e) => handleInputChange("postalCode", e)}
        onBlur={() => handleBlur("postalCode", address.postalCode)}
        error={!!addressError.postalCode}
        helperText={addressError.postalCode}
        sx={{ gridColumn: { xs: "1/2", md: "1" } }}
      />
      <TextField
        label="Stad"
        variant="outlined"
        value={address.city}
        onChange={(e) => handleInputChange("city", e)}
        onBlur={() => handleBlur("city", address.city)}
        error={!!addressError.city}
        helperText={addressError.city}
        sx={{ gridColumn: { xs: "2/4", md: "2/5" } }}
      />
    </Box>
  );
}

export default AddressForm;
