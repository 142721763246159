import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const apiClient = axios.create({
    baseURL: baseURL,
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
    }
});

let isRefreshing = false; // Flag to check if refresh is in progress
let refreshSubscribers = []; // To hold failed requests while token is being refreshed

// Function to process the queue after token refresh
const onRefreshed = (newAccessToken) => {
    refreshSubscribers.forEach((callback) => callback(newAccessToken));
    refreshSubscribers = [];
};

// Function to add subscribers to queue
const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};

// Custom hook to handle navigation
export const useAxiosInterceptor = () => {

    apiClient.interceptors.request.use((config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    apiClient.interceptors.response.use((response) => {
        return response;
    }, async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // If the token is already being refreshed, queue the requests
            if (isRefreshing) {
                return new Promise((resolve) => {
                    addRefreshSubscriber((newAccessToken) => {
                        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                        resolve(apiClient(originalRequest));
                    });
                });
            }

            // Set refreshing state
            isRefreshing = true;

            try {
                const refreshToken = localStorage.getItem('refresh_token');
                // if (!refreshToken) throw new Error('No refresh token available');

                const response = await axios.post(`${baseURL}/auth/refresh-token`, { refresh_token: refreshToken });

                const newAccessToken = response.data.access_token;
                const newRefreshToken = response.data.refresh_token;

                // Update the tokens
                localStorage.setItem('access_token', newAccessToken);
                localStorage.setItem('refresh_token', newRefreshToken);

                // Process all requests in queue with the new token
                onRefreshed(newAccessToken);

                // Retry the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                return apiClient(originalRequest);
            } catch (err) {
                console.error('Token refresh failed:', err);
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                console.log("Redirecting to login page");

                return Promise.reject(err);
            } finally {
                // Reset the refreshing state
                isRefreshing = false;
            }
        }

        return Promise.reject(error);
    });
};

export default apiClient;
