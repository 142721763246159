import { Box, Typography } from "@mui/material";
import PageContainer from "../components/PageContainer";
import { useAnimate } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RegistrationFieldsSelector from "../components/setupEventComponents/RegistrationFieldsSelector";

function SetupEventPage() {
  const [scope, animate] = useAnimate();
  const [changesMade, setChangesMade] = useState(true);

  useEffect(() => {
    const sequence = async () => {
      // Start with initial states
      //await animate(".eventName", { opacity: 0, y: 0 });
      await animate(".setupComponent", { opacity: 0, scale: 0.1 });

      // Move event name up and zoom in the setup component simultaneously
      setTimeout(async () => {
        // Event name moves up
        // await animate(
        //   ".eventName",
        //   { opacity: 1, y: "-5vh" },
        //   { duration: 1, ease: "easeOut" }
        // );

        // Setup component zooms in and fades in
        await animate(
          ".setupComponent",
          { opacity: [0, 1], scale: [0.1, 1] },
          { duration: 1 }
        );
      }, 500);
    };

    sequence();
  }, [animate]);

  const { eventURN } = useParams();
  const eventName = eventURN.toUpperCase().replace(/-/g, " ");

  return (
    <PageContainer>
      <Box
        sx={{
          position: "fixed",
          textAlign: "center",
          backgroundColor: "white",
          borderBottom: "1px solid lightgray",
          paddingTop: "2rem",
          width: 1,
          zIndex: 1200,
          top: "4rem",
          display: "flex",
          alignItems: "center", // Vertically center items
          justifyContent: "center", // Center event name initially
          paddingX: "2rem",
          gap: "1rem", // Optional: space between items
        }}
      >
        {/* Centered event name */}
        <Typography
          className="eventName"
          variant="h5"
          sx={{padding: "0rem 1rem"}}
        >
          {eventName}
        </Typography>

        {/* Conditionally render right-side text */}
      </Box>
      <Box
        ref={scope}
        sx={{
          textAlign: "center",
          mt: "8em",
          mb: "4em",
          pt: "2em",
          "& .eventName": { opacity: 0, transform: "translateY(0)" },
          "& .setupComponent": { opacity: 0, transform: "scale(0.1)" },
        }}
      >
        {/* Setup Component */}
        <Box
          className="setupComponent"
          sx={{
            marginTop: "2rem",
          }}
        >
          <RegistrationFieldsSelector />
        </Box>
      </Box>
    </PageContainer>
  );
}

export default SetupEventPage;
