import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import apiClient from "../services/apiclient";
import { UserContext } from "../contexts/UserContext";
import LoadingScreen from "../components/LoadingScreen";

function LogoutPage() {
  const { setIsLoggedIn } = useContext(UserContext);
  const [loggedOut, setLoggedOut] = useState(false);
  const [delayReached, setDelayReached] = useState(false);

  useEffect(() => {
    const logout = async () => {
      const refreshToken = localStorage.getItem("refresh_token");
      console.log(refreshToken);

      try {
        apiClient.post("/auth/logout", {
          refresh_token: refreshToken,
        });
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        setIsLoggedIn(false);
        setLoggedOut(true);

        // Set a timeout to ensure at least 2 seconds of delay
        setTimeout(() => {
          setDelayReached(true);
        }, 2000); // 2 seconds delay
      } catch (error) {
        console.log(error);
        // Handle the error appropriately, such as showing a message to the user
      }
    };

    logout();
  }, []);

  if (!loggedOut || !delayReached) {
    return <LoadingScreen />; // Show loading screen while logging out and ensuring delay
  }

  return <Navigate to="/login" />;
}

export default LogoutPage;
