import React, { useState } from "react";
import {
  Box,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  IconButton,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet, useLocation, useParams, Link } from "react-router-dom";
function EventNavbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const { eventURN } = useParams();
  const eventName = eventURN.toUpperCase().replace(/-/g, " ");

  // Derive base path from current location
  const segments = location.pathname.split("/").filter(Boolean);
  const basePath = segments.slice(0, 2).join("/");
  const isSetup = !!(segments[2] === "setup");

  const activeTabStyle = {
    backgroundColor: "var(--blue)",
    "& a": {
      color: "white",
    },
  };
  const idleTabStyle = {
    backgroundColor: "transparent",
    "& a": {
      color: "black",
    },
    "& a:hover": {
      color: "var(--blue)",
    },
  };
  console.log(segments);
  console.log(isSetup);
  if (isSetup) {
    return (
      <Box>
        <Outlet />
      </Box>
    );
  }
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <Box sx={{ display: "flex" }}>
      {/* Main Sidebar */}
      <Box
        sx={{
          position: { xs: "absolute", md: "fixed" },
          display: { xs: "none", md: "block" },
          top: "4em", // Start below navbar (4em offset)
          left: 0,
          width: "18rem", // Sidebar width
          height: "100%", // Full height
          backgroundColor: "#f5f5f5", // Sidebar background color
          borderRight: "1px solid #ddd", // Sidebar border
          zIndex: 10, // Ensure it's above content
          boxSizing: "border-box",
        }}
      >
        <Typography
          variant="h2"
          sx={{ textAlign: "center", m: "1.2rem", fontSize: "1rem" }}
        >
          <strong>{eventName}</strong>
        </Typography>
        <List
          sx={{
            fontSize: "0.875rem",
            width: "100%",
            boxSizing: "border-box",
            color: "black",
            justifyContent: "center",
            textAlign: "center",
            fontWeight: "bold",
            textTransform: "uppercase",
            letterSpacing: ".2em",
            "& li": {
              width: "100%",
              textAlign: "center",
              padding: "1em",
            },
            "& li a": {
              width: "100%",
              display: "block",
              textDecoration: "none",
              // "&:hover": {
              //   color: "var(--blue)",
              // },
            },
          }}
        >
          <ListItem
            sx={
              location.pathname === `/${basePath}`
              ? activeTabStyle
              : idleTabStyle
            }>
              <Link to="">Dashboard</Link>
            </ListItem>
          <ListItem
            sx={
              location.pathname === `/${basePath}/posts`
                ? activeTabStyle
                : idleTabStyle
            }
          >
            <Link to="posts">posten</Link>
          </ListItem>
          <ListItem
            sx={
              location.pathname === `/${basePath}/shifts`
                ? activeTabStyle
                : idleTabStyle
            }
          >
            <Link to="shifts">shiften</Link>
          </ListItem>
          <ListItem
            sx={
              location.pathname === `/${basePath}/registration-form`
                ? activeTabStyle
                : idleTabStyle
            }
          >
            <Link to="registration-form">registratieformulier</Link>
          </ListItem>
          <ListItem
            sx={
              location.pathname === `/${basePath}/teams`
                ? activeTabStyle
                : idleTabStyle
            }
          >
            <Link to="teams">teams</Link>
          </ListItem>
        </List>
      </Box>
      {/*top navbar*/}
      <Box
        sx={{
          position: "fixed",
          top: "4rem",
          justifyContent: "center",
          alignItems: "center",
          display: { xs: "flex", md: "none" },
          height: "4rem",
          width: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
          sx={{
            padding: ".2em",
            border: "1px solid black",
            borderRadius: ".2em",
            position: "absolute",
            left: 20,
            display: { xs: "flex", md: "none" }, // Show on small screens
          }}
        >
          <MenuIcon htmlColor="black" />
        </IconButton>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "20rem",
            margin: "0 4rem",
          }}
        >
          {eventName}
        </Typography>
      </Box>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: "block", md: "none" }, // Show on small screens
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            flexDirection: "column",
            height: "100%",
            backgroundColor: "#f5f5f5",
            color: "black",
            zIndex: 10,
            "& a": {
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              textDecoration: "none",
              color: "inherit",
              "&:hover": {
                textDecoration: "none",
              },
            },
            "& li": {
              display: "flex",
              width: "100%",
              padding: "0",
            },
            "& span": {
              textAlign: "center",
            },
            
          }}
        >
          <Link to="">
            <Typography
              variant="h6"
              sx={{ margin: "1em 0", fontWeight: "bold" }}
            >
              {eventName}
            </Typography>
          </Link>
          <List
            sx={{
              width: "100%",
              padding: 0,
              "& li": {
                width: "100%",
                padding: 0,
              },
            }}
          >
            <Link to="">
              <ListItem onClick={handleDrawerToggle}>
                <ListItemButton>
                  <ListItemText primary="dashboard" />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="posts">
              <ListItem onClick={handleDrawerToggle}>
                <ListItemButton>
                  <ListItemText primary="posten" />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="shifts">
              <ListItem onClick={handleDrawerToggle}>
                <ListItemButton>
                  <ListItemText primary="shiften" />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="registration-form">
              <ListItem onClick={handleDrawerToggle}>
                <ListItemButton>
                  <ListItemText primary="registratieformulier" />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="teams">
              <ListItem onClick={handleDrawerToggle}>
                <ListItemButton>
                  <ListItemText primary="teams" />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </Box>
      </Drawer>
      {/* Main Content */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: { xs: "center", md: "start" },
          marginLeft: { xs: 0, md: "18rem" }, // Adjust for sidebar width
          top: { xs: "4rem", md: "0rem" }, // Offset for navbar
          flexGrow: 1, // Fills remaining width
          // padding: "1em 0em",
          height: { xs: "calc(100vh - 8em)", md: "calc(100vh - 4em)" }, // Subtract navbar height
          overflowY: "auto", // Scroll if content exceeds viewport height
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default EventNavbar;
