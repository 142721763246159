import React, { useContext, useState } from "react";
import {
  Box,
  IconButton,
  Drawer,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout"; // Import Logout Icon
import { Outlet, Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useEffect } from "react";
import apiClient from "../services/apiclient";
function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isLoggedIn, setIsLoggedIn, loading, setLoading } =
    useContext(UserContext); // Assume logged in for this example
  const [eventCount, setEventCount] = useState();
  useEffect(() => {
    const fetchData = () => {
      apiClient
        .get("/events/count")
        .then((response) => setEventCount(response.data.count))
        .catch(() => {});
    };
    fetchData();
  }, []);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  if (loading) {
    return null; // You can return a loading indicator here if desired
  } else {
    return (
      <Box
        sx={{
          position: "relative",
          left: 0,
          right: 0,
          top: 0,
          height: "auto",
          zIndex: 1200, // Ensure the navbar is above other content
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: 0,
            backgroundColor: "#1F1F1F",
            height: "4em",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", md: "space-between" }, // Center on small screens, space-between on medium and up
            zIndex: 1200, // Ensure the navbar is above other content
            "& a": {
              textDecoration: "none",
              color: "inherit",
            },
          }}
        >
          {/* Burger Menu Icon */}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{
              padding: ".2em",
              border: "1px solid white",
              borderRadius: ".2em",
              position: "absolute",
              left: 20,
              display: { xs: "flex", md: "none" }, // Show on small screens
            }}
          >
            <MenuIcon htmlColor="white" />
          </IconButton>

          {/* Logo */}
          <Box
            component="img"
            src="/logo2.svg" // Path to your logo
            alt="Logo"
            sx={{
              marginLeft: { xs: 0, md: "2em" },
              height: { xs: "2rem", md: "2.5rem" }, // Adjust logo size as needed
              display: { xs: "block", md: "block" }, // Show on all screens
            }}
          />

          {/* Navigation Links for Larger Screens */}
          <Box
            sx={{
              color: "white",
              display: { xs: "none", md: "flex" }, // Show only on medium and larger screens
              gap: "2em", // Space between links
              marginRight: "2em",
              alignItems: "center", // Align profile items vertically
            }}
          >
            <Link to="/events">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button color="inherit">
                  events{" "}
                  {eventCount && (
                    <Typography
                      variant="span"
                      sx={{
                        fontSize: "1rem",
                        display: "flex",
                        backgroundColor: "var(--blue)",
                        color: "white",
                        marginLeft: ".2rem",
                        borderRadius: "1rem",
                        padding: "0.5rem",
                        width: "1rem",
                        height: "1rem",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {eventCount}
                    </Typography>
                  )}
                </Button>
              </Box>
            </Link>
            {/* Always visible */}
            {isLoggedIn ? (
              <>
                <Link to="/dashboard">
                  <Button color="inherit">dashboard</Button>
                </Link>
                {/* Profile Section */}
                <Link to="/profile">
                  <Button color="inherit">profiel</Button>
                </Link>
                {/* Logout Icon Button */}
                <Link to="/logout">
                  <IconButton color="inherit" sx={{ ml: 1 }}>
                    <LogoutIcon />
                  </IconButton>
                </Link>
              </>
            ) : (
              <>
                <Link to="/login">
                  <Button variant="outlined" color="inherit">
                    aanmelden
                  </Button>
                </Link>
                <Link to="/register">
                  <Button
                    sx={{
                      color: "black",
                      backgroundColor: "white",
                    }}
                    variant="contained"
                    color="inherit"
                  >
                    registreren
                  </Button>
                </Link>
              </>
            )}
          </Box>
        </Box>

        {/* Drawer for Mobile View */}
        <Drawer
          anchor="top"
          open={drawerOpen}
          onClose={handleDrawerClose}
          sx={{
            display: { xs: "block", md: "none" }, // Show on small screens
            zIndex: 1200,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              flexDirection: "column",
              height: "100%",
              backgroundColor: "#1F1F1F",
              color: "white",
              "& a": {
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                textDecoration: "none",
                color: "inherit",
                "&:hover": {
                  textDecoration: "none",
                },
              },
              "& li": {
                padding: 0,
              },
              "& span": {
                textAlign: "center",
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "1em 0" }}
            >
              Voluntex
            </Typography>
            <List
              sx={{
                width: "100%",
                padding: 0,
              }}
            >
              <Link to="/events">
                <ListItem onClick={handleDrawerClose}>
                  <ListItemButton>
                    <ListItemText primary="events" />
                  </ListItemButton>
                </ListItem>
              </Link>
              {isLoggedIn ? (
                <>
                  <Link to="/dashboard">
                    <ListItem onClick={handleDrawerClose}>
                      <ListItemButton>
                        <ListItemText primary="dashboard" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="/profile">
                    <ListItem onClick={handleDrawerClose}>
                      <ListItemButton>
                        <ListItemText primary="profiel" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="/logout">
                    <ListItem onClick={handleDrawerClose}>
                      <ListItemButton>
                        <ListItemText primary="uitloggen" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/login">
                    <ListItem onClick={handleDrawerClose}>
                      <ListItemButton>
                        <ListItemText primary="Aanmelden" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to="/register">
                    <ListItem onClick={handleDrawerClose}>
                      <ListItemButton>
                        <ListItemText primary="Registreren" />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </>
              )}
            </List>
          </Box>
        </Drawer>
        <Box
          sx={{
            marginTop: "4rem",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    );
  }
}
export default Navbar;
