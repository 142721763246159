import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useHistoryState } from "@uidotdev/usehooks";
import apiClient from '../services/apiclient';

function useRegistrationFields() {
  const { eventURN } = useParams()
  const [fields, setFields] = useState([]);
  const [types, setTypes] = useState([]);
  useEffect(() => {
    window.scrollTo(0,0)
    const fetchData = async () => {

      apiClient.get(`/manage/${eventURN}/registration-form/data`)
        .then(response => {
          let processedFields = [];
          const data = response.data
          data.default_questions.forEach((question, index) => {
            const typeInfo = data.types.find(type => type.id === question.type_id)
            processedFields.push({
              id: question.id,
              label: question.label,
              type_id: typeInfo.id,
              options: [],
              unchangeable: true,
              unremoveable: true,
              category: "default",
              visible: true,

              error: "",
            })
          })


          data.proposed_questions.forEach((question, index) => {
            const typeInfo = data.types.find(type => type.id === question.type_id)
            const visibility = data.selected_proposed_questions.includes(question.id)
            processedFields.push({
              id: index,
              question_id: question.id,
              label: question.label,
              type_id: typeInfo.id,
              options: [],
              unchangeable: true,
              unremoveable: false,
              required: question.required,
              category: "proposed",
              visible: visibility,
              error: "",
            })
          })
            data.custom_questions.forEach((question, index) => {
              const typeInfo = data.types.find(type => type.id === question.type_id)
              processedFields.push({
                id: processedFields.length + 1,
                question_id: question.id, // Unique ID for new fields
                label: question.label,
                type_id: typeInfo.id,
                options: question.options.map(option => option.label),
                required: question.required,
                unchangeable: false,
                unremoveable: false,
                multiSelect: question.multi_select,
                category: "custom",
                visible: true,
                error: "",
              })
            })
            setFields(processedFields)
            setTypes(data.types)
          })

            .catch(err =>
              console.error("Failed to fetch event data:", err)
            )
        }
    fetchData();
    }, [eventURN])

  // Add a new field
  function addField() {
    setFields(prevFields => [
      ...prevFields,
      {
        id: Date.now(), // Unique ID for new fields
        label: "nieuw veld",
        type_id: types.find(type => type.type === "text").id,
        options: ["", "", ""],
        required: true,
        unchangeable: false,
        unremoveable: false,
        multiSelect: false,
        category: "custom",
        visible: true,
        error: "",
      },
    ]);
  }

  // Update a field
  function handleFieldChange(id, arg, value) {
    let errorText="";
    if (arg === "error") {
      errorText = value;

    }

    setFields(prevFields =>
      prevFields.map(fieldItem =>
        fieldItem.id === id
          ? { ...fieldItem, [arg]: value, error: errorText}
          : fieldItem
      )
    );
  }

  // Add an option to a field
  function addOption(fieldId) {
    setFields(prevFields =>
      prevFields.map(field =>
        field.id === fieldId
          ? { ...field, options: [...field.options, ""] }
          : field
      )
    );
  }

  // Handle option change
  function handleOptionChange(fieldId, optionIndex, newValue) {
    setFields(prevFields =>
      prevFields.map(field =>
        field.id === fieldId
          ? {
            ...field,
            options: field.options.map((option, idx) =>
              idx === optionIndex ? newValue : option
            ),
          }
          : field
      )
    );
  }

  // Remove an option from a field
  function removeOption(fieldId, optionIndex) {
    setFields(prevFields =>
      prevFields.map(field =>
        field.id === fieldId
          ? {
            ...field,
            options: field.options.filter((_, idx) => idx !== optionIndex),
          }
          : field
      )
    );
  }

  // Remove a field
  function removeField(id) {
    const field = fields.find(field => field.id === id);
    if (field?.unchangeable) {
      handleFieldChange(field.id, "visible", false);
      return;
    }
    setFields(prevFields => prevFields.filter(field => field.id !== id));
  }

  return {
    fields,
    types,
    setFields,
    addField,
    handleFieldChange,
    addOption,
    handleOptionChange,
    removeOption,
    removeField
  };
}

export default useRegistrationFields;
