// src/contexts/UserContext.js
import React, { createContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../services/apiclient";

// Create the context
export const EventContext = createContext();

// Create a provider component
export const EventProvider = ({ children }) => {
    const { eventURN } = useParams();

    // Global state
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [setupStartDate, setSetupStartDate] = useState(null);
    const [setupEndDate, setSetupEndDate] = useState(null);
    const [eventStartDate, setEventStartDate] = useState(null);
    const [eventEndDate, setEventEndDate] = useState(null);
    const [breakdownStartDate, setBreakdownStartDate] = useState(null);
    const [breakdownEndDate, setBreakdownEndDate] = useState(null);

    useEffect(() => {
        // Check login status on component mount
        apiClient.get(`/manage/${eventURN}/details`)
            .then(response=> {
                response = response.data;
                if (response.setup_start_date) {
                    setStartDate(response.setup_start_date)
                    setEndDate(response.breakdown_end_date)
                } else {
                    setStartDate(response.event_start_date)
                    setEndDate(response.event_end_date)
                }
                setSetupStartDate(response.setup_start_date)
                setSetupEndDate(response.setup_end_date)
                setEventStartDate(response.event_start_date)
                setEventEndDate(response.event_end_date)
                setBreakdownStartDate(response.breakdown_start_date)
                setBreakdownEndDate(response.breakdown_end_date)
            })
            .catch(error => {
                console.error("Failed to check user login status:", error);
            })
            .finally(() => {
            });
    }, []); // Empty dependency array means this runs once on mount
    console.log(eventStartDate, eventEndDate);
    return (
        <EventContext.Provider value={{
            startDate,
            endDate,
            setupStartDate,
            setupEndDate,
            eventStartDate,
            eventEndDate,
            breakdownStartDate,
            breakdownEndDate
        }}>
            {children}
        </EventContext.Provider>
    );
};
