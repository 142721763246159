import { Box } from "@mui/material";

function InnerBox({
  width = null,
  height = null,
  maxWidth = null,
  maxHeight = null,
  flexDirection = "column",
  margin = "1em",
  padding = "1em",
  position = "static",
  children,
}) {
  return (
    <Box
      sx={{
        position: position,
        width: width,
        height: height,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        margin: margin,
        padding: padding,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: flexDirection,
        borderRadius: "10px",
        border: "1px solid lightgray",
      }}
    >
      {children}
    </Box>
  );
}

export default InnerBox;
