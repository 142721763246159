import {
  Box,
  TextField,
  Switch,
  FormControlLabel,
  Tooltip,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  Snackbar,
  AccordionActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Checkbox,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useState, useContext, useId } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiClient from "../services/apiclient";
import { EventContext } from "../contexts/EventContext";
import dayjs from "dayjs";
import { format } from "date-fns";
function PostForm({ mode }) {
  const { eventURN, id: stringId } = useParams();

  const { startDate, endDate } = useContext(EventContext);
  console.log(startDate, endDate);
  const id = Number(stringId);
  console.log(id);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [ageRestrictionToggle, setAgeRestrictionToggle] = useState(false);
  const [codeToggle, setCodeToggle] = useState(false);
  const [ageRestriction, setAgeRestriction] = useState();
  const [code, setCode] = useState();
  const [shifts, setShifts] = useState([]);

  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [ageRestrictionError, setAgeRestrictionError] = useState("");
  const [codeError, setCodeError] = useState("");

  const [existingNames, setExistingNames] = useState([]);
  const [existingCodes, setExistingCodes] = useState([]);
  const [targetNotFound, setTargetNotFound] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState("confirmation");
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogContent, setDialogContent] = useState("");

  const [newShiftFormVisible, setNewShiftFormVisible] = useState(false);
  const [shiftStartDate, setShiftStartDate] = useState();
  const [shiftStartDateError, setShiftStartDateError] = useState("");
  const [shiftEndDate, setShiftEndDate] = useState();
  const [shiftEndDateError, setShiftEndDateError] = useState("");
  const [shiftStartTime, setShiftStartTime] = useState();
  const [shiftStartTimeError, setShiftStartTimeError] = useState("");
  const [shiftEndTime, setShiftEndTime] = useState();
  const [shiftEndTimeError, setShiftEndTimeError] = useState("");
  const [shiftError, setShiftError] = useState();
  useEffect(() => {
    const fetchData = async () => {
      apiClient
        .get(`/manage/${eventURN}/posts/data?time_slots=true`)
        .then((response) => {
          console.log(response.data);
          const data = response.data.filter((post) => {
            console.log(post.id, id);
            return mode === "create" || (mode === "update" && post.id !== id);
          });
          console.log(data);

          const names = data.map((post) => post.name);
          const codes = data
            .map((post) => post.code)
            .filter((code) => code !== null);

          if (mode === "update") {
            const target = response.data.find((post) => post.id === id);
            if (!target) {
              setTargetNotFound(true);
              return;
            }
            setName(target.name);
            setDescription(target.description);
            setAgeRestrictionToggle(!!target.ageRestriction);
            setCodeToggle(!!target.code);
            setAgeRestriction(target.ageRestriction);
            setCode(target.code);
            setShifts(sortShifts(target.shifts));
          } else if (mode === "create") {
            apiClient
              .get(`/manage/${eventURN}/time_slots/data`)
              .then((timeSlotResponse) => {
                setShifts(sortShifts(timeSlotResponse.data));
              });
          }

          console.log(codes);
          setExistingNames(names);
          setExistingCodes(codes);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    fetchData();
  }, []); // Add necessary dependencies here

  useEffect(() => {
    console.log("shifts:", shifts);
  }, [shifts]);

  const handleSubmit = (e) => {
    console.log(codeToggle, !code);
    let hasError = false;
    if (!name) {
      hasError = true;
      setNameError("geef een geldige naam in voor deze post");
    }
    if (existingNames.includes(true)) {
      hasError = true;
      setNameError("deze naam is al in gebruik");
    }
    if (!description) {
      hasError = true;
      setDescriptionError("geef een geldige beschrijving");
    }

    if (codeToggle && !code) {
      hasError = true;
      setCodeError("geef een geldig code");
    }
    if (ageRestrictionToggle && !ageRestriction) {
      console.log("triggered");
      hasError = true;
      setAgeRestrictionError("geef een geldig leeftijd");
    }
    if (hasError) {
      return;
    }
    const payload = {
      name: name,
      description: description,
      age_restriction: ageRestriction,
      code: code,
      shifts: shifts,
    };
    if (mode === "create") {
      apiClient
        .post(`/manage/${eventURN}/posts/create`, payload)
        .then((response) => {
          console.log(response.data);
          navigate(-1);
        });
    } else if (mode === "update") {
      console.log("update version submit");
      apiClient
        .post(`/manage/${eventURN}/posts/save?id=${id}`, payload)
        .then((response) => {
          console.log(response.data);
          navigate("..");
        });
    }
  };

  const handleDelete = (e) => {
    apiClient
      .delete(`/manage/${eventURN}/posts/delete?id=${id}`)
      .then(() => navigate(-1));
    console.log(id + "is deleted");
  };

  const handleCloseDialog = () => setDialogOpen(false);
  const handleOpenDialog = () => setDialogOpen(true);

  const addShift = () => {
    let error = false;
    if (!shiftStartDate) {
      error = true;
      setShiftStartDateError("geef een geldige startdatum");
    }
    if (!shiftEndDate) {
      error = true;
      setShiftEndDateError("geef een geldige einddatum");
    }
    if (!shiftStartTime) {
      error = true;
      setShiftStartTimeError("geef een geldige starttijd");
    }
    if (!shiftEndTime) {
      error = true;
      setShiftEndTimeError("geef een geldige eindtijd");
    }

    if (error) {
      return;
    }
    const startDate = shiftStartDate.format("YYYY-MM-DD");
    const startDateTime = `${startDate}T${shiftStartTime.format("HH:mm")}+02:00`;
    const endDateTime = `${shiftEndDate.format(
      "YYYY-MM-DD"
    )}T${shiftEndTime.format("HH:mm")}+02:00`;

    // Log statements for debugging
    console.log(shiftEndDate.format("YYYY-MM-DD"));
    console.log(shiftStartTime.format("HH:mm:ss"));

    setShifts((prev_shifts) => {
      // Create a deep copy of the previous shifts to avoid direct state mutation
      const updatedShifts = { ...prev_shifts };
      // Check if there is already an entry for the current start date
      if (!updatedShifts[startDate]) {
        // Initialize an array for the shifts on this date if it doesn't exist
        updatedShifts[startDate] = [];
      } else {
        // Check if the combination of startDateTime and endDateTime already exists
        const existingShift = updatedShifts[startDate].some(
          (shift) => shift.start === startDateTime && shift.end === endDateTime
        );

        if (existingShift) {
          setShiftError("deze shift is reeds geregistreerd");
          console.log("Shift with the same start and end time already exists.");
          return prev_shifts; // If it exists, do nothing and return the current state
        }
      }
      setShiftStartDate(null);
      setShiftEndDate(null);
      setShiftStartTime(null);
      setShiftEndTime(null);
      // Add the new shift to the corresponding date's array
      updatedShifts[startDate].push({
        start: startDateTime,
        end: endDateTime,
        max_volunteers: 0, // the variable is in camel casing, because this  simplifies the integration with the back-end.
        used: true,
      });

      return sortShifts(updatedShifts);
    });
  };

  const updateShiftField = (date, shiftIndex, updatedFields) => {
    setShifts((prevShifts) => {
      // Create a deep copy of the previous shifts state
      const updatedShifts = { ...prevShifts };

      // Ensure the date exists
      if (!updatedShifts[date]) return prevShifts; // If the date doesn't exist, do nothing

      // Check if the index is valid
      if (shiftIndex < 0 || shiftIndex >= updatedShifts[date].length) {
        console.error("Invalid shift index.");
        return prevShifts; // If the index is invalid, do nothing
      }

      // If the shift is found by index, update only the provided fields
      updatedShifts[date][shiftIndex] = {
        ...updatedShifts[date][shiftIndex], // Keep the existing fields
        ...updatedFields, // Merge in the new fields (e.g. max_volunteers, used)
      };

      return sortShifts(updatedShifts);
    });
  };

  const sortShifts = (shifts) => {
    const sortedDates = Object.keys(shifts).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    const sortedShifts = {};

    sortedDates.forEach((date) => {
      sortedShifts[date] = shifts[date].sort((a, b) => {
        const startCompare = new Date(a.start) - new Date(b.start);
        if (startCompare !== 0) {
          return startCompare; // If different, return the comparison
        }
        return new Date(a.end) - new Date(b.end); // Secondary sort by endDateTime
      });
    });
    return sortedShifts;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
        width: "100%",
        marginBottom: "2rem",
      }}
    >
      {targetNotFound ? (
        <>
          <p>Er is geen post gevonden met het identificatienummer {id}.</p>
          <Button
            variant="contained"
            onClick={() => {
              navigate(-1);
            }}
          >
            keer terug
          </Button>
        </>
      ) : (
        <>
          {mode === "create" ? <h2>nieuwe post</h2> : <h2>{name}</h2>}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr  1fr 1fr",
              // gridTemplateRows: "1fr 1.5fr 1fr auto 1fr auto",
              alignItems: "flex-start",
              gap: ".4rem",
              columnGap: "1rem",
              justifyContent: "center",
              width: { xs: "90%", md: "70%" },
              maxWidth: "30rem",
            }}
          >
            <h4>INSTELLINGEN</h4>
            <TextField
              label="naam"
              variant="outlined"
              fullWidth
              sx={{
                gridColumn: "1/4",
              }}
              helperText={nameError}
              error={!!nameError}
              value={name}
              onChange={(e) => {
                if (existingNames.includes(e.target.value)) {
                  console.log("triggered");
                  setNameError("deze naam bestaat al");
                } else {
                  setNameError("");
                }
                setName(e.target.value);
              }}
            ></TextField>
            <TextField
              sx={{
                gridColumn: "1/4",
              }}
              inputProps={{
                maxLength: 185,
              }}
              label="beschrijving"
              multiline
              rows={3}
              variant="outlined"
              fullWidth
              value={description}
              error={!!descriptionError}
              helperText={descriptionError}
              onChange={(e) => {

                if ((e.target.value.match(/\n/g) || []).length < 3) {
                  setDescription(e.target.value);
                }
              }}
            ></TextField>
            <Tooltip
              arrow
              placement="left"
              title="Wanneer dit geselecteerd is, zal de post verborgen zijn met een code. Enkel zij die de code ingeven zullen de post kunnen zien."
            >
              <FormControlLabel
                sx={{
                  gridColumn: "1/3",
                }}
                label="verbergen met code"
                control={
                  <Switch
                    checked={codeToggle}
                    onChange={(e) => {
                      setCode("");
                      setCodeError("");
                      setCodeToggle(e.target.checked);
                    }}
                  ></Switch>
                }
              ></FormControlLabel>
            </Tooltip>
            <TextField
              sx={{
                gridColumn: "3",
              }}
              inputProps={{
                maxLength: 6,
              }}
              InputLabelProps={{
                shrink: !!code,
              }}
              label="code"
              variant="outlined"
              fullWidth
              value={code}
              disabled={!codeToggle}
              error={!!codeError}
              helperText={codeError}
              onChange={(e) => {
                const regex = /^[a-zA-Z0-9]{0,8}$/;

                if (regex.test(e.target.value)) {
                  setCodeError("");
                  setCode(e.target.value);
                }
              }}
            />
            {existingCodes.includes(code) && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "justify",
                  gridColumn: "1/4",
                  padding: ".5rem",
                  borderRadius: "1rem",
                  backgroundColor: "var(--lightblue)",
                  border: ".5rem solid var(--blue)",
                }}
              >
                <WarningIcon
                  sx={{
                    marginRight: "1rem",
                  }}
                />
                <p>
                  deze code wordt reeds gebruikt voor één of meerdere posten.
                  Wanneer de code wordt ingegeven zullen alle posten met deze
                  code, zichtbaar worden.
                </p>
              </Box>
            )}
            <Tooltip
              arrow
              placement="left"
              title="Wanneer dit geselecteerd is, zal de post alleen beschikbaar zijn voor mensen met een minimumleeftijd. De post zal nog wel zichtbaar zijn, maar geblokkeerd."
            >
              <FormControlLabel
                sx={{
                  gridColumn: "1/3",
                  gridRow: "5",
                }}
                label="leeftijdsbeperking"
                control={
                  <Switch
                    checked={ageRestrictionToggle}
                    onChange={(e) => {
                      setAgeRestriction("");
                      setAgeRestrictionError("");
                      setAgeRestrictionToggle(e.target.checked);
                    }}
                  />
                }
              ></FormControlLabel>
            </Tooltip>
            <TextField
              label="leeftijd"
              value={ageRestriction}
              InputLabelProps={{
                shrink: !!ageRestriction,
              }}
              inputProps={{
                maxLength: 3,
              }}
              disabled={!ageRestrictionToggle}
              error={!!ageRestrictionError}
              helperText={ageRestrictionError}
              onChange={(e) => {
                const regex = /^\d{0,3}$/;
                if (regex.test(e.target.value) && e.target.value < 120) {
                  setAgeRestrictionError("");
                  setAgeRestriction(e.target.value);
                }
              }}
              sx={{
                gridRow: "5",
              }}
            />
            <h4>SHIFTEN</h4>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gridRow: "7",
                gridColumn: "1/4",
              }}
            >
              {Object.entries(shifts).map(([date, shiftsArray]) => {
                const totalUsedShifts = shiftsArray.reduce(
                  (total, shift) => total + (shift.used ? 1 : 0),
                  0
                );
                console.log(totalUsedShifts);
                return (
                  <Accordion
                    key={date}
                    disableGutters
                    square={true}
                    elevation={0}
                    slotProps={{ heading: { component: "h4" } }}
                    sx={{
                      width: "100%",
                      borderBottom: "1px solid lightgray",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls={`${date}-content`}
                      id={`${date}-header`}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gridTemplateColumns: "10fr 1fr",
                          justifyContent: "space-between",
                          width: "100%",
                          marginRight: "2rem",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                          }}
                        >
                          {dayjs(date).format("dddd DD/MM")}
                        </Typography>
                        {totalUsedShifts ? (
                          <Typography
                            variant="p"
                            sx={{
                              display: "flex",
                              backgroundColor: "var(--blue)",
                              color: "white",
                              borderRadius: "1rem",
                              padding: "0 .5rem",
                              minWidth: "1rem",
                              width: "fit-content",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            {totalUsedShifts}
                          </Typography>
                        ) : null}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        border: "solid lightgray",
                        borderWidth: "1px 0 1px 0",
                        display: "grid",
                        gridTemplateColumns: "1fr", // Defines 4 equal columns
                        gap: 2, // Optional: Adjust spacing between items
                        alignItems: "center", // Align vertically in the center
                      }}
                    >
                      <Box
                        sx={{
                          display: "grid",
                          justifyitems: "center",
                          alignItems: "center",

                          gridTemplateColumns: {
                            xs: "1fr 1fr 1fr 1fr",
                            md: "1fr 2fr 2fr 1fr",
                          },
                          gap: "1rem",
                          textTransform: "uppercase",
                          "& h6": {
                            fontWeight: "bold",
                            fontSize: ".8rem",
                            textAlign: "center",
                          },
                        }}
                      >
                        <Typography variant="h6">actief</Typography>
                        <Typography variant="h6">start</Typography>
                        <Typography variant="h6">einde</Typography>
                        <Typography variant="h6">max.</Typography>
                      </Box>
                      {shiftsArray.map((shift, index) => (
                        <Box
                        key={`${date}-${index}`}
                          sx={{
                            display: "grid",
                            justifyItems: "center",
                            alignItems: "center",

                            gridTemplateColumns: {
                              xs: "1fr 1fr 1fr 1fr",
                              md: "1fr 2fr 2fr 1fr",
                            },
                            gap: "1rem",
                            "& p": {
                              textAlign: "center",
                            },
                          }}
                        >
                          <Checkbox
                            sx={{
                              width: "fit-content",
                              height: "fit-content",
                            }}
                            checked={!!shift.used}

                            onChange={(e) =>
                              updateShiftField(date, index, {
                                used: e.target.checked,
                                max_volunteers: 0,
                              })
                            }
                          />
                          <p>{format(shift.start, "dd/MM HH:mm")}</p>
                          <p>{format(shift.end, "dd/MM HH:mm")}</p>
                          <TextField
                            sx={{
                              minWidth: "5rem",
                            }}
                            value={shift.max_volunteers}
                            onChange={(e) => {
                              const regex = /^\d{0,4}$/;
                              if (regex.test(e.target.value)) {
                                updateShiftField(date, index, {
                                  max_volunteers: e.target.value,
                                });
                              }
                            }}
                            disabled={!shift.used}
                          />
                        </Box>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gridRow: "8",
                gridColumn: "1/4",
              }}
            >
              {!newShiftFormVisible && (
                <Button
                  startIcon={<AddIcon />}
                  variant="outlined"
                  sx={{
                    width: "max-content",
                  }}
                  onClick={() => setNewShiftFormVisible(true)}
                >
                  nieuwe shift
                </Button>
              )}
            </Box>
            <Box
              sx={{
                //gridRow: "9",
                gridColumn: "1/4",
              }}
            >
              {newShiftFormVisible && (
                <Box
                  sx={{
                    display: "grid",
                    columnGap: "1rem",
                    rowGap: "1rem",
                    justifyContent: "center",
                    alignItems: "start",
                    gridTemplateColumns: "1fr auto 1fr",
                  }}
                >
                  <DatePicker
                    label="Startdatum"
                    format="DD/MM"
                    minDate={dayjs(startDate)}
                    maxDate={
                      shiftEndDate ? dayjs(shiftEndDate) : dayjs(endDate)
                    }
                    slotProps={{
                      textField: {
                        error: !!shiftStartDateError,
                        helperText: shiftStartDateError,
                      },
                    }}
                    views={["month", "day"]}
                    sx={{
                      minWidth: "2.5rem",
                      width: "fit-content",
                      gridRow: "1",
                      gridColumn: "1",
                    }}
                    value={shiftStartDate}
                    onChange={(e) => {
                      setShiftStartDateError();
                      setShiftError();
                      setShiftStartDate(e);
                      console.log(shiftStartDate);
                    }}
                  />
                  <TimePicker
                    label="starttijd"
                    value={shiftStartTime}
                    timezone="Europe/Paris"
                    onChange={(e) => {
                      setShiftStartTimeError();
                      setShiftError();
                      setShiftStartTime(e);
                    }}
                    slotProps={{
                      textField: {
                        error: !!shiftStartTimeError,
                        helperText: shiftStartTimeError,
                      },
                    }}
                    maxTime={
                      shiftStartDate?.isSame(shiftEndDate, "day")
                        ? shiftEndTime
                        : null
                    }
                    sx={{
                      gridRow: "2",
                      gridColumn: "1",
                    }}
                  />
                  <Typography
                    variant="p"
                    sx={{
                      minWidth: "2.5rem",
                      textAlign: "center",
                      gridRow: "1/3",
                      gridColumn: "2",
                    }}
                  >
                    -
                  </Typography>
                  <DatePicker
                    label="einddatum"
                    sx={{
                      minWidth: "2.5rem",
                      gridRow: "1",
                      gridColumn: "3",
                    }}
                    value={shiftEndDate}
                    slotProps={{
                      textField: {
                        error: !!shiftEndDateError,
                        helperText: shiftEndDateError,
                      },
                    }}
                    onChange={(e) => {
                      setShiftEndDateError();
                      setShiftError();
                      setShiftEndDate(e);
                    }}
                    minDate={
                      shiftStartDate ? dayjs(shiftStartDate) : dayjs(startDate)
                    }
                    maxDate={dayjs(endDate)}
                    format="DD/MM"
                    views={["month", "day"]}
                  />
                  <TimePicker
                    label="eindtijd"
                    value={shiftEndTime}
                    timezone="Europe/Paris"
                    slotProps={{
                      textField: {
                        error:!!shiftEndTimeError,
                        helperText: shiftEndTimeError,
                      },
                    }}
                    onChange={(e) => {
                      setShiftEndTimeError();
                      setShiftError();
                      setShiftEndTime(e);
                    }}
                    minTime={
                      shiftStartDate?.isSame(shiftEndDate, "day")
                        ? shiftStartTime
                        : null
                    }
                    sx={{
                      minWidth: "2.5rem",
                      gridRow: "2",
                      gridColumn: "3",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "var(--red)",
                      gridRow: "3",
                      gridColumn: "1/4",
                      textAlign: "center",
                    }}
                  >
                    {shiftError}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gridRow: "4",
                      gridColumn: "1/4",
                      justifyContent: "center",
                      margin: ".5rem 0",
                    }}
                  >
                    <Button variant="outlined" onClick={() => addShift()}>
                      Opslaan
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            {mode === "update" && (
              <IconButton
                sx={{
                  //gridRow: "9",
                  gridColumn: "1",
                  marginRight: "2rem",
                  backgroundColor: "#BABABABA",
                  borderRadius: ".5rem",
                  width: "fit-content",
                  "&:hover": {
                    backgroundColor: "var(--red)",
                  },
                }}
                onClick={() => {
                  setDialogMode("confirmation");
                  setDialogTitle(`post '${name}' verwijderen`);
                  setDialogContent(
                    `Weet je zeker dat je de post wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.`
                  );
                  handleOpenDialog();
                }}
              >
                <DeleteIcon className="del-icon" sx={{ color: "white" }} />
              </IconButton>
            )}

            <Button
              variant="contained"
              sx={{
                //gridRow: "9",
                gridColumn: "2",

                backgroundColor: "#BABABABA",
                "&:hover": {
                  backgroundColor: "#929292BA",
                },
              }}
              onClick={() => navigate(-1)}
            >
              annuleren
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              sx={{
                //gridRow: "9",
                gridColumn: "3",
              }}
            >
              opslaan
            </Button>
          </Box>
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
              <span dangerouslySetInnerHTML={{ __html: dialogContent }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Annuleren</Button>
              <Button
                color="error"
                onClick={() => {
                  handleCloseDialog();
                  handleDelete();
                }}
              >
                Verwijderen
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}
export default PostForm;
