import {
  Box,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Select,
  FormControlLabel,
  Switch,
  FormControl,
  Typography,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AnimatedBox from "../AnimatedBox";
import LoadingScreen from "../LoadingScreen";
import useRegistrationFields from "../../hooks/useRegistrationFields";
import apiClient from "../../services/apiclient";
import { useNavigate, useParams } from "react-router-dom";
function RegistrationFieldsSelector({ direction }) {
  const { eventURN } = useParams();
  const navigate = useNavigate();
  const {
    fields,
    types,
    addField,
    handleFieldChange,
    addOption,
    handleOptionChange,
    removeOption,
    removeField,
  } = useRegistrationFields();
  function handleSubmit() {
    console.log("checkpoint");
    const filtered_list = fields
      .filter((item) => !item.unremoveable)
      .map((item, index) => {
        const { id, unremoveable, unchangeable, error, ...rest } = item;
        return { ...rest, order: index + 1 };
      });
    let selected_proposed_fields = [];
    let custom_fields = [];
    let customOrderId = 1;
    let hasError = false;
    fields.map((field, index) => {
      const typeInfo = types.find((type) => type.id === field.type_id);
      if (field.label === "nieuw veld" || !field.label) {
        handleFieldChange(field.id, "error", "vul een geldige titel in voor dit veld.");
        
        hasError = true;
      }
      if (field.category === "proposed" && field.visible) {
        selected_proposed_fields.push(field.question_id);
      } else if (field.category === "custom") {
        let options = [];
        if (typeInfo.type === "multiple choice") {
          options = field.options;
        } else {
          options = [];
        }

        console.log("custom question detected");
        custom_fields.push({
          order_id: customOrderId,
          question_id: field.question_id,
          label: field.label,
          type_id: field.type_id,
          required: field.required,
          options: options,
          multi_select: field.multiSelect,
        });
        customOrderId++;
      }
    });
    const data = {
      selected_proposed_questions: selected_proposed_fields,
      custom_questions: custom_fields,
    };
    if (hasError) {
      return;
    } else {
      apiClient.post(`/manage/${eventURN}/registration-form/save`, {
        selected_proposed_questions: selected_proposed_fields,
        custom_questions: custom_fields,
      });
      navigate(`/manage/${eventURN}/posts`)
    }
  }
  return (
    <AnimatedBox direction={direction}>
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "center",
        }}
        variant="h5"
      >
        Selecteer de registratievelden
      </Typography>
      {fields.length != 0
        ? fields.map(
            (field) =>
              field.visible && (
                <Box
                  key={field.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    maxWidth: "40em",
                    margin: "0 2rem",
                    marginBottom: "1em",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 6fr 3fr 1fr", // Adjust grid to 6:3:1 ratio for TextField, Select, and IconButton
                      gap: "1em",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Box width="2em"></Box>
                    <TextField
                      sx={{
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      value={field.label}
                      onChange={(e) =>
                        handleFieldChange(field.id, "label", e.target.value)
                      }
                      disabled={field.unchangeable}
                      error={!!field.error}
                      helperText={field.error}
                    />
                    <Select
                      sx={{
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      value={field.type_id}
                      onChange={(e) =>
                        handleFieldChange(field.id, "type_id", e.target.value)
                      }
                      disabled={field.unchangeable}
                    >
                      {types.map((type) => (
                        <MenuItem value={type.id} disabled={type.locked}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {!field.unremoveable && (
                      <IconButton
                        sx={{
                          justifySelf: "end",
                          ":hover": { color: "red" },
                        }}
                        onClick={() => removeField(field.id)}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    )}
                  </Box>

                  {!field.unremoveable && (
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 9fr 1fr",
                        gap: "1em",
                        width: "100%",
                      }}
                    >
                      <Box width="2em" />
                      <FormControlLabel
                        sx={{
                          justifyContent: "left",
                        }}
                        label="verplichte vraag"
                        control={
                          <Switch
                            checked={!!field.required}
                            disabled={field.unchangeable}
                            onChange={(e) => {
                              handleFieldChange(
                                field.id,
                                "required",
                                e.target.checked
                              );
                            }}
                          />
                        }
                      />
                    </Box>
                  )}

                  {types.find((type) => type.id === field.type_id).type ===
                    "multiple choice" &&
                    !field.unchangeable && (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 9fr 1fr",
                          gap: "1em",
                          width: "100%",
                        }}
                      >
                        <Box width="2em" />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={field.multiSelect}
                              onChange={(e) =>
                                handleFieldChange(
                                  field.id,
                                  "multiSelect",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="meerdere keuzes toestaan"
                          sx={{
                            justifyContent: "left",
                          }}
                        />
                        {field.options.map((option, index) => (
                          <Box
                            key={index}
                            sx={{
                              gridColumn: "2",
                              display: "flex",
                              alignItems: "center",
                              gap: "1em",
                            }}
                          >
                            <TextField
                              fullWidth
                              value={option}
                              onChange={(e) =>
                                handleOptionChange(
                                  field.id,
                                  index,
                                  e.target.value
                                )
                              }
                              label={`Optie ${index + 1}`}
                            />
                            {index > 1 && (
                              <IconButton
                                onClick={() => removeOption(field.id, index)}
                                color="neutral"
                                sx={{
                                  gridColumn: "3",
                                  ":hover": { color: "red" },
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            )}
                          </Box>
                        ))}
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            gridColumn: "1/span 3",
                          }}
                        >
                          <Button
                            sx={{ marginBottom: "2em" }}
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => addOption(field.id)}
                          >
                            Optie toevoegen
                          </Button>
                        </Box>
                      </Box>
                    )}
                </Box>
              )
          )
        : null}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: ".5em",
        }}
      >
        {fields.length != 0
          ? fields.map(
              (field) =>
                field.category === "proposed" &&
                !field.visible && (
                  <Button
                    key={field.id}
                    variant="outlined"
                    color="warning"
                    startIcon={<AddIcon />}
                    onClick={() => handleFieldChange(field.id, "visible", true)}
                    sx={{
                      marginBottom: "0.5em",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {field.label}
                  </Button>
                )
            )
          : null}
      </Box>
      <Button
        sx={{ marginTop: "1em" }}
        variant="outlined"
        color="primary"
        onClick={addField}
        startIcon={<AddIcon />}
      >
        Veld toevoegen
      </Button>
      {/* {!fields.filter((field) => field.id === 7)[0].visible && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "1em",
                textAlign: "center",
              }}
            >
              <WarningIcon />
              <p>
                Het rijksregisternummer is vereist indien u de module IDScan
                wenst te gebruiken
              </p>
            </Box>
          )} */}
      <Button
        variant="contained"
        sx={{
          margin: "1em",
          padding: ".5em 3em",
        }}
        onClick={handleSubmit}
      >
        opslaan
      </Button>
    </AnimatedBox>
  );
}

export default RegistrationFieldsSelector;
