import { useState, useEffect } from "react";
import Card from "../components/Card";
import LinearProgress from "@mui/material/LinearProgress";
import PageContainer from "../components/PageContainer";
import {
  Box,
  TextField,
  Button,
  FormControlLabel,
  FormControl,
  Switch,
  Slider,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DateRangePicker from "../components/formComponents/DateRangePicker";
import CloseIcon from "@mui/icons-material/Close";
import AnimatedBox from "../components/AnimatedBox";
import InnerBox from "../components/InnerBox";
import AddressForm from "../components/formComponents/AddressForm";

import apiClient from "../services/apiclient";
import { useNavigate, Link } from "react-router-dom";
function CreateEventPage() {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [direction, setDirection] = useState("next");

  const [eventName, setEventName] = useState("");
  const [eventNameError, setEventNameError] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventDescriptionError, setEventDescriptionError] = useState("");

  const [eventType, setEventType] = useState("");
  const [eventTypeError, setEventTypeError] = useState("");
  const [eventTypeOther, setEventTypeOther] = useState("");
  const [eventTypeOtherError, setEventTypeOtherError] = useState("");

  const [setupBreakdownDisabled, setSetupBreakdownDisabled] = useState(true);
  const [setupStartDate, setSetupStartDate] = useState(null);
  const [setupEndDate, setSetupEndDate] = useState(null);
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [breakdownStartDate, setBreakdownStartDate] = useState(null);
  const [breakdownEndDate, setBreakdownEndDate] = useState(null);

  const [setupStartError, setSetupStartError] = useState("");
  const [setupEndError, setSetupEndError] = useState("");
  const [eventStartError, setEventStartError] = useState("");
  const [eventEndError, setEventEndError] = useState("");
  const [breakdownStartError, setBreakdownStartError] = useState("");
  const [breakdownEndError, setBreakdownEndError] = useState("");


  const [address, setAddress] = useState({
    street: "",
    number: "",
    postalCode: "",
    city: "",
  });
  const [streetError, setStreetError] = useState("");
  const [numberError, setNumberError] = useState("");
 
  const [cityError, setCityError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [fullAddressEnabled, setFullAddressEnabled] = useState(true);
  
  const [capacity, setCapacity] = useState(60);
  var maxStep = 5;

  useEffect(() => {
    if (setupBreakdownDisabled) {
      // Reset setup dates when setupBreakdownDisabled is true
      setSetupStartDate(null);
      setSetupEndDate(null);
      setBreakdownStartDate(null);
      setBreakdownEndDate(null);
    }
  }, [setupBreakdownDisabled]);

  function handleSubmit() {
    apiClient
      .post("/events/request", {
        event_name: eventName,
        description: eventDescription,
        event_type: {
          category: eventType,
          other: eventTypeOther,
        },
        address: {
          street: address.street,
          number: address.number,
          city: address.city,
          postal_code: address.postalCode,
        },

        date: {
          setup_start_date: formatDate(setupStartDate), //fill in correct format
          setup_end_date: formatDate(setupEndDate), //fill in correct format
          event_start_date: formatDate(eventStartDate), //fill in correct format
          event_end_date: formatDate(eventEndDate), //fill in correct format
          breakdown_start_date: formatDate(breakdownStartDate), //fill in correct format
          breakdown_end_date: formatDate(breakdownEndDate), //fill in correct format
        },
        capacity: capacity,
      })
      .then(() => {
        navigate("/dashboard");
      });
  }
  function nextStep() {
    if (step === 1) {
      let hasError = false;
      if (!eventName) {
        setEventNameError(
          "Gelieve een geldige naam op te geven (letters, cijfers, spaties en -)"
        );
        hasError = true;
      }
      if (!eventDescription) {
        setEventDescriptionError(
          "Gelieve een passende omschrijving op te geven"
        );
        hasError = true;
      }
      if (!eventType) {
        setEventTypeError("Gelieve een type op te geven");
        hasError = true;
      }
      if (eventType === "other" && !eventTypeOther) {
        setEventTypeOtherError("Gelieve een type op te geven");
        hasError = true;
      }
      if (hasError) {
        return;
      } else {
        setDirection("next");
        setStep(2);
      }
    } else if (step === 2) {
      let hasError = false;
      if (!setupBreakdownDisabled) {
        if (!setupStartDate) {
          setSetupStartError("Gelieve een startdatum op te geven");
          hasError = true;
        }
        if (!setupEndDate) {
          setSetupEndError("Gelieve een einddatum op te geven");
          hasError = true;
        }
        if (!breakdownStartDate) {
          setBreakdownStartError("Gelieve een startdatum op te geven");
          hasError = true;
        }
        if (!breakdownEndDate) {
          setBreakdownEndError("Gelieve een einddatum op te geven");
          hasError = true;
        }
      }
      if (!eventStartDate) {
        setEventStartError("Gelieve een startdatum op te geven");
        hasError = true;
      }
      if (!eventEndDate) {
        setEventEndError("Gelieve een einddatum op te geven");
        hasError = true;
      }
      if (hasError) {
        return; // Prevent moving to the next step if there's an error
      }
      setDirection("next");
      setSetupStartError();
      setSetupEndError();
      setBreakdownStartError();
      setBreakdownEndError();
      setEventStartError();
      setEventEndError();

      setStep(3);
    } else if (step === 3) {
      let hasError = false;
      if (fullAddressEnabled) {
        if (!address.street) {
          setStreetError("vul een straatnaam in");
          hasError = true;
        }
        if (!address.number) {
          setNumberError("vul een huisnummer in");
          hasError = true;
        }
      }
      if (!address.postalCode) {
        setPostalCodeError("vul een postcode in");
        hasError = true;
      }
      if (!address.city) {
        setCityError("vul een plaatsnaam in");
        hasError = true;
      }
      if (hasError) {
        return;
      } else {
        setStep(4);
      }
    } else if (step === 4) {
      setStep(5);
    } else if (step === 5) {
    }
  }
  function previousStep() {
    if (step > 1) {
      setDirection("prev");
      setStep(step - 1);
    }
  }

  function formatDate(date) {
    console.log(date);
    if (date === null) {
      console.log(date);
      return date;
    }
    date = new Date(date);
    return date.toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }
  // Helper function to calculate days between two dates
  function calculateDays(startDate, endDate) {
    if (!startDate || !endDate) return 0;

    const start = new Date(startDate);
    const end = new Date(endDate);
    if (start > end) return 0;
    const timeDiff = end - start;
    const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)) + 1;

    return dayDiff;
  }

  function renderDaysInfo(startDate, endDate, brackets) {
    const days = calculateDays(startDate, endDate);
    let returnString = "";
    if (days > 0) {
      if (days > 1) {
        returnString = `${days} dagen`;
      } else {
        console.log("days is 1");
        returnString = "1 dag";
      }
      if (brackets) {
        returnString = `(${returnString})`;
      }
      return returnString;
    }
  }

  return (
    <PageContainer>
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "right",
          }}
        >
          <IconButton
            sx={{
              margin: "0",
              marginRight: "1.2em",
              marginTop: "1.2em",
            }}
            onClick={() => navigate("/dashboard")}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            marginTop: "1em",
            textAlign: "center",
            textJustify: "center",
          }}
        >
          <h1>Nieuw evenement</h1>
        </Box>
        <Card height="auto">
          <Box
            sx={{
              width: "100%",
              height: "auto",
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                textAlign: "left",
                overflowX: "hidden",
              }}
            >
              {step === 1 && (
                <AnimatedBox direction={direction}>
                  <h3>Wat is de naam en beschrijving van uw evenement?</h3>
                  <TextField
                    id="eventName"
                    label="Naam evenement"
                    inputProps={{ maxLength: 30 }}
                    sx={{
                      width: "100%",
                      maxWidth: "40em",
                      marginBottom: "1em",
                    }}
                    value={eventName}
                    onChange={(e) => {
                      setEventName(e.target.value);
                      setEventNameError("");
                    }}
                    error={!!eventNameError}
                    helperText={eventNameError}
                  />
                  <TextField
                    inputProps={{ maxLength: 300 }}
                    sx={{
                      width: "100%",
                      maxWidth: "40em",
                      marginBottom: "1em",
                    }}
                    id="eventDescription"
                    label="Beschrijving evenement"
                    value={eventDescription}
                    onChange={(e) => {
                      setEventDescription(e.target.value);
                      console.log(eventDescription);
                    }}
                    multiline
                    rows={5}
                    error={!!eventDescriptionError}
                    helperText={eventDescriptionError}
                  ></TextField>
                  <Select
                    sx={{
                      width: "100%",
                      maxWidth: "40em",
                      marginBottom: ".5em",
                    }}
                    value={eventType}
                    helperText={eventTypeError}
                    error={!!eventTypeError}
                    onChange={(e) => {
                      setEventType(e.target.value);
                      setEventTypeError("");
                      setEventTypeOther("");
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Selecteer een evenement type
                    </MenuItem>
                    <MenuItem value="music festival">muziekfestival</MenuItem>
                    <MenuItem value="film festival">filmfestival</MenuItem>
                    <MenuItem value="cultural festival">
                      cultuurfestival
                    </MenuItem>
                    <MenuItem value="art exhibition">
                      kunsttentoonstelling
                    </MenuItem>
                    <MenuItem value="art fair">kunstbeurs</MenuItem>
                    <MenuItem value="book fair">boekenbeurs</MenuItem>
                    <MenuItem value="science fair">wetenschapsbeurs</MenuItem>
                    <MenuItem value="craft fair">ambachtsmarkt</MenuItem>
                    <MenuItem value="theater production">
                      theatervoorstelling
                    </MenuItem>
                    <MenuItem value="open house">opendeurdag</MenuItem>
                    <MenuItem value="sports tournament">sporttoernooi</MenuItem>
                    <MenuItem value="other">anders</MenuItem>
                  </Select>
                  {eventType === "other" && (
                    <TextField
                      label="type evenement"
                      value={eventTypeOther}
                      sx={{
                        width: "100%",
                        maxWidth: "40em",
                        marginBottom: "1em",
                      }}
                      helperText={eventTypeOtherError}
                      error={!!eventTypeOtherError}
                      onChange={(e) => {
                        setEventTypeOther(e.target.value);
                        setEventTypeOtherError("");
                      }}
                    ></TextField>
                  )}
                </AnimatedBox>
              )}
              {step === 2 && (
                <AnimatedBox direction={direction}>
                  <h3>Wanneer vindt uw evenement plaats?</h3>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!setupBreakdownDisabled}
                        onChange={(e) =>
                          setSetupBreakdownDisabled(!e.target.checked)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Opbouw en afbraak"
                  />
                  {!setupBreakdownDisabled && (
                    <Box>
                      <p>
                        Opbouw{" "}
                        {renderDaysInfo(setupStartDate, setupEndDate, true)}
                      </p>
                      <DateRangePicker
                        startLabel="Start opbouw"
                        endLabel="Einde opbouw"
                        startDate={setupStartDate}
                        endDate={setupEndDate}
                        maxEndDate={eventStartDate}
                        setStartDate={setSetupStartDate}
                        setEndDate={setSetupEndDate}
                        startError={setupStartError}
                        endError={setupEndError}
                      />
                      <p>
                        Evenement
                        {renderDaysInfo(eventStartDate, eventEndDate, true)}
                      </p>
                      <DateRangePicker
                        startLabel="Start evenement"
                        endLabel="Einde evenement"
                        minStartDate={setupEndDate}
                        maxEndDate={breakdownStartDate}
                        startDate={eventStartDate}
                        endDate={eventEndDate}
                        setStartDate={setEventStartDate}
                        setEndDate={setEventEndDate}
                        startError={eventStartError}
                        endError={eventEndError}
                      />
                      <p>
                        Afbraak
                        {renderDaysInfo(
                          breakdownStartDate,
                          breakdownEndDate,
                          true
                        )}
                      </p>
                      <DateRangePicker
                        startLabel="Start afbraak"
                        endLabel="Einde afbraak"
                        minStartDate={eventEndDate}
                        startDate={breakdownStartDate}
                        endDate={breakdownEndDate}
                        setStartDate={setBreakdownStartDate}
                        setEndDate={setBreakdownEndDate}
                        startError={breakdownStartError}
                        endError={breakdownEndError}
                      />
                    </Box>
                  )}
                  {setupBreakdownDisabled && (
                    <Box>
                      <p>
                        Evenement{" "}
                        {renderDaysInfo(eventStartDate, eventEndDate, true)}
                      </p>
                      <DateRangePicker
                        startLabel="Start evenement"
                        endLabel="Einde evenement"
                        startDate={eventStartDate}
                        endDate={eventEndDate}
                        setStartDate={setEventStartDate}
                        setEndDate={setEventEndDate}
                        startError={eventStartError}
                        endError={eventEndError}
                      />
                    </Box>
                  )}
                </AnimatedBox>
              )}
              {step === 3 && (
                <AnimatedBox direction={direction}>
                  <h3>Waar vindt uw evenement plaats?</h3>
                  <Box
                    sx={{
                      maxWidth: "30em",
                    }}
                  >
                    <FormControlLabel
                      sx={{ justifyContent: "center" }}
                      control={
                        <Switch
                          checked={fullAddressEnabled}
                          onChange={(e) => setFullAddressEnabled(e.target.checked) }
                        />
                      }
                      label="Volledig adres"
                    />
                    <AddressForm
                      defaultAddress={address}
                      onChange={(e) => setAddress(e.data)}
                      streetError={streetError}
                      setStreetError={setStreetError}
                      numberError={numberError}
                      setNumberError={setNumberError}
                      setPostalCodeError={setPostalCodeError}
                      postalCodeError={postalCodeError}
                      setCityError={setCityError}
                      cityError={cityError}
                      fullAddressEnabled={fullAddressEnabled}/>
                  </Box>
                </AnimatedBox>
              )}
              {step === 4 && (
                <AnimatedBox direction={direction}>
                  <Box
                    sx={{
                      width: "70%",
                      maxWidth: "40em",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: "1em",
                      textAlign: "center",
                    }}
                  >
                    <h3>capaciteit</h3>
                    <p>
                      Hoeveel vrijwilligers verwacht u maximaal op uw evenement?
                    </p>
                    <Slider
                      aria-label="Always visible"
                      defaultValue={100}
                      value={capacity}
                      onChange={(e, newValue) => setCapacity(newValue)}
                      step={20}
                      marks={[
                        { value: 0, label: "0" },
                        { value: 100, label: "100" },
                        { value: 200, label: "200" },
                        { value: 300, label: "300" },
                        { value: 400, label: "400" },
                        { value: 500, label: "500" },
                        { value: 600, label: "600" },
                        { value: 700, label: "700" },
                        { value: 800, label: "800" },
                        { value: 900, label: "900" },
                        { value: 1000, label: "1000+" },
                      ]}
                      min={60}
                      max={1000}
                      valueLabelDisplay="on"
                      sx={{
                        marginTop: "2em",
                      }}
                      valueLabelFormat={(value) =>
                        value >= 1000 ? "1000+" : value
                      }
                    />
                  </Box>
                </AnimatedBox>
              )}
              {step === 5 && (
                <AnimatedBox direction={direction}>
                  <h3>Bevestiging</h3>
                  <p>U heeft de volgende details ingevuld:</p>
                  <InnerBox>
                    <Box
                      width="100%"
                      display="grid"
                      gridTemplateColumns="1fr 2fr"
                      columnGap=".5em"
                      marginTop="1em"
                      sx={{
                        "& p": {
                          marginBottom: ".2em", // Adjust this value as needed
                          marginTop: ".2em",
                        },
                      }}
                    >
                      <p>
                        <strong>Naam:</strong>
                      </p>
                      <p>{eventName}</p>

                      <p>
                        <strong>Beschrijving:</strong>
                      </p>
                      <p>{eventDescription}</p>

                      {setupBreakdownDisabled ? (
                        <>
                          <p>
                            <strong>Startdatum:</strong>
                          </p>
                          <p>
                            {new Date(eventStartDate).toLocaleDateString(
                              "nl-NL",
                              {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </p>

                          <p>
                            <strong>Einddatum:</strong>
                          </p>
                          <p>
                            {new Date(eventEndDate).toLocaleDateString(
                              "nl-NL",
                              {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </p>

                          <p>
                            <strong>Event Duur:</strong>
                          </p>
                          <p>
                            {renderDaysInfo(eventStartDate, eventEndDate, false)
                              .replace("(", "")
                              .replace(")", "")}
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            <strong>Startdatum:</strong>
                          </p>
                          <p>
                            {new Date(setupStartDate).toLocaleDateString(
                              "nl-NL",
                              {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </p>
                          <p>
                            <strong>Einddatum:</strong>
                          </p>
                          <p>
                            {new Date(breakdownEndDate).toLocaleDateString(
                              "nl-NL",
                              {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </p>

                          <p>
                            <strong>Opbouw Duur:</strong>
                          </p>
                          <p>
                            {renderDaysInfo(
                              setupStartDate,
                              setupEndDate,
                              false
                            )}
                          </p>

                          <p>
                            <strong>Evenement Duur:</strong>
                          </p>
                          <p>
                            {renderDaysInfo(
                              eventStartDate,
                              eventEndDate,
                              false
                            )}
                          </p>

                          <p>
                            <strong>Afbraak Duur:</strong>
                          </p>
                          <p>
                            {renderDaysInfo(
                              breakdownStartDate,
                              breakdownEndDate,
                              false
                            )}
                          </p>
                        </>
                      )}

                      <p>
                        <strong>capiciteit:</strong>
                      </p>
                      <p>
                        {capacity < 1000 ? `${capacity}` : `1000+`}{" "}
                        vrijwilligers
                      </p>
                    </Box>
                  </InnerBox>
                </AnimatedBox>
              )}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {step > 1 && (
                  <Button
                    sx={{ margin: "1em", marginLeft: "0" }}
                    variant="contained"
                    color="primary"
                    onClick={previousStep}
                  >
                    <ArrowBackIosIcon />
                  </Button>
                )}
                {step < maxStep ? (
                  <Button
                    sx={{ margin: "1em", marginRight: "0" }}
                    variant="contained"
                    color="primary"
                    onClick={nextStep}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                ) : (
                  <Button
                    sx={{ margin: "1em", marginRight: "0" }}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    aanvraag indienen
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={{ width: "70%", maxWidth: "20em", margin: 0, padding: 0 }}>
              <LinearProgress
                variant="determinate"
                value={(step / maxStep) * 100}
              />
              <h3>
                Stap {step} van {maxStep}{" "}
              </h3>
            </Box>
          </Box>
        </Card>
      </Box>
    </PageContainer>
  );
}

export default CreateEventPage;
