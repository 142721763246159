import PageContainer from "../components/PageContainer";
import {
  Box,
} from '@mui/material'
import PostForm from "../components/PostForm";

function UpdatePostPage() {
  return (
    <PageContainer>
        <PostForm mode="update"/>
    </PageContainer>
  );
}
export default UpdatePostPage;
